<template>
  <div class="shop-num">
    <el-form ref="form" label-position="top" label-width="80px" :model="formData" :rules="rules">
      <el-form-item label="显示内容" prop="content">
        <el-tooltip
          content="变量说明:{all_buy_people}为人数数量，{number}为时间区间"
          placement="right"
          effect="light"
        >
          <i class="el-icon-question" style="position: absolute"></i>
        </el-tooltip>
        <el-input v-model="formData.content" size="medium"></el-input>
      </el-form-item>
      <el-form-item label="图标">
        <div class="icons-wrap">
          <div
            :class="['icon-item', formData.icon === item ? 'active' : 0]"
            v-for="item in iconData"
            :key="item"
            @click="selectIcon(item)"
          >
            <i :class="['detailmarkettool-iconfont', item]"></i>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="时间区间" prop="param.days">
        <el-input v-model.number="formData.param.days" size="medium">
          <template #suffix> 天内 </template>
        </el-input>
      </el-form-item>
      <el-form-item label="购买人数">
        <el-radio-group v-model="formData.param.viewType">
          <el-radio :label="1">真实人数</el-radio>
          <el-radio :label="2">虚拟人数</el-radio>
        </el-radio-group>
      </el-form-item>
      <div v-if="showTemplate" class="tem-wrap">
        <el-form-item label="真实人数">
          <el-tooltip
            content="使用真实人数数量进行计算，如果同时选择“增加”和“乘以”，则先增加再乘以"
            placement="right"
            effect="light"
          >
            <i class="el-icon-question" style="position: absolute; left: 62px"></i>
          </el-tooltip>
          <div class="num-setting">
            <el-space>
              <el-checkbox v-model="formData.param.addType">增加</el-checkbox>
              <el-form-item prop="param.add" style="margin-right: 10px">
                <el-input v-model.number="formData.param.add" size="medium">
                  <template #suffix> 人 </template>
                </el-input>
              </el-form-item>
              <el-checkbox v-model="formData.param.mulType">乘以</el-checkbox>
              <el-form-item prop="param.mul">
                <el-input v-model.number="formData.param.mul" size="medium">
                  <template #suffix> 倍 </template>
                </el-input>
              </el-form-item>
            </el-space>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from 'vue';
import { ElMessage } from 'element-plus';

export default {
  name: 'ShopNum',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const formData = ref({});
    watchEffect(() => {
      formData.value = JSON.parse(JSON.stringify(props.data));
      if (!formData.value.param) {
        // 这里设置默认数据
        formData.value.param = {
          viewType: 1,
          addType: true,
          mulType: false,
        };
      }
      emit('dataChange', formData.value);
    });

    watch(formData.value, cur => {
      emit('dataChange', cur);
    });

    const showTemplate = computed(() => {
      if (formData.value.param) {
        return formData.value.param.viewType == 2;
      }
      return false;
    });

    // 选择图标
    const selectIcon = icon => {
      formData.value.icon = icon;
    };

    const form = ref();
    // 验证
    const validate = callback => {
      form.value.validate((valid, errs) => {
        if (
          !formData.value.param.mulType &&
          !formData.value.param.addType &&
          formData.value.param.viewType === 2
        ) {
          ElMessage.warning('请至少选择一个计算方式');
          valid = false;
        }
        // 如果没有勾选 计算方式，即便计算的数值没有通过验证，也允许提交
        if (!valid) {
          let onlyType = false; // 判断 errs 中是否有除了 add 和 mul 以外的错误
          // 遍历设置 onlyType 的值
          for (const type in errs) {
            if (Object.hasOwnProperty.call(errs, type)) {
              const err = errs[type][0]['field'];
              if (err !== 'param.add' && err !== 'param.mul') {
                onlyType = true;
              }
            }
          }
          if (!onlyType) {
            if (
              !(
                (!formData.value.param.addType && errs['param.add']) || // 没有勾选 “增加” 选项，但是输入框有错误的情况
                // 没有勾选 “乘法” 选项，但是输入框有错误的情况
                (!formData.value.param.mulType && errs['param.mul'])
              )
            ) {
              return;
            }
          } else {
            return;
          }
        }
        if (formData.value.param.viewType === 1) {
          formData.value.param.addType = true;
          formData.value.param.mulType = false;
          formData.value.param.add = undefined;
          formData.value.param.mul = undefined;
        } else {
          if (!formData.value.param.addType) {
            formData.value.param.add = undefined;
          }
          if (!formData.value.param.mulType) {
            formData.value.param.mul = undefined;
          }
        }
        callback();
      });
    };

    const validNum = (rule, value, callback) => {
      if (value && /[A-z]/.test(value)) {
        callback(new Error('请输入正整数'));
      }
      callback();
    };

    const validateAddNum = (rule, val, callback) => {
      if (!formData.value.param.addType) callback();

      if (!val) {
        callback(new Error('请输入增加数'));
      }
      callback();
    };

    const validateMulNum = (rule, val, callback) => {
      if (!formData.value.param.mulType) callback();

      if (!val) {
        callback(new Error('请输入乘以数'));
      }
      callback();
    };

    return {
      formData,
      selectIcon,
      showTemplate,
      validate,
      form,
      iconData: [
        'icon-meiyuan',
        'icon-meiyuanqian',
        'icon-1',
        'icon-Dollar-circle-fill',
        'icon-meiyuanqianbao',
      ],
      rules: {
        content: [{ required: true, whitespace: true, message: '请填写显示内容', trigger: 'blur' }],
        'param.days': [
          { required: true, message: '请填写时间区间', trigger: 'blur' },
          { type: 'number', min: 1, message: '请输入正整数', trigger: 'blur' },
          { validator: validNum, trigger: 'blur' },
        ],
        'param.add': [
          { type: 'number', min: 1, message: '增加数只能是正整数', trigger: 'blur' },
          { validator: validNum, trigger: 'blur' },
          { validator: validateAddNum, trigger: 'blur' },
        ],
        'param.mul': [
          { type: 'number', min: 1, message: '乘以数只能是正整数', trigger: 'blur' },
          { validator: validNum, trigger: 'blur' },
          { validator: validateMulNum, trigger: 'blur' },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../statics/scss/iconfont';
@import '@/assets/scss/tool';
.icons-wrap {
  background-color: $formBg;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  .icon-item {
    width: 46px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 2px;
    border: solid 1px #d7dde3;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    transition: border-color 0.3s linear;

    &.active {
      border: 2px solid $theme;
      i.detailmarkettool-iconfont {
        color: $theme;
      }
    }
    i.detailmarkettool-iconfont {
      display: inline-block;
      font-size: 26px;
      color: #666666;
    }
  }
}
.util {
  position: absolute;
  right: 10px;
}
.tem-wrap {
  background-color: $formBg;
  padding: 10px 20px;
}
.num-setting {
  .el-input {
    width: 146px;
  }
}
.two-inputs-wrap {
  display: flex;
  width: 450px;
  div {
    flex: 1;
  }
  .connect-icon {
    flex: 0 0 30px;
    display: flex;
    justify-content: center;
  }
}
.el-input {
  width: 100%;
  max-width: 450px;
}
.el-select {
  width: 100%;
  max-width: 450px;
}
.el-icon-question {
  position: absolute;
  top: -30px;
  font-size: 16px;
  left: 70px;
  color: #666666;
  cursor: pointer;
}
</style>
