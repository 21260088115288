<script lang="jsx">
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MFormSkeleton from '@/components/formSkeleton/FormSkeleton.vue';
import MFormFooter from '@/components/formFooter/FormFooter.vue';
import Stock from '../components/Stock.vue';
import BrowseNum from '../components/BrowseNum.vue';
import ViewNum from '../components/ViewNum.vue';
import ShopNum from '../components/ShopNum.vue';
import Time from '../components/Time.vue';
import CartNum from '../components/CartNum.vue';
import Custom from '../components/Custom.vue';
import RecencyShopping from '../components/RecencyShopping.vue';

import { getConfig, saveConfig } from '../api/listConfig';
import { getRoute } from '../../../tools/config';
import { ElMessage } from 'element-plus';

const myComponents = {
  Stock,
  BrowseNum,
  ViewNum,
  ShopNum,
  Time,
  CartNum,
  Custom,
  RecencyShopping,
};

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const listRouter = getRoute('list'); // 列表路由
    const configData = ref({});
    const loading = ref(false);
    const setConfigData = async () => {
      const { status, data } = await getConfig({ id: route.params.id }, loading);
      if (status) {
        configData.value = data;
      } else {
        router.push(listRouter);
      }
    };

    const saveData = ref({});
    // 获取需要保存的数据
    const changeSaveData = data => {
      saveData.value = data;
    };

    const saveLoading = ref(false);
    const curComponent = ref();
    // 保存数据
    const save = () => {
      curComponent.value.validate(async () => {
        const { icon, content, param } = saveData.value;
        const data = {
          icon,
          content,
          param,
        };
        const res = await saveConfig(route.params.id, data, saveLoading);
        if (res.status) {
          ElMessage.success('保存成功');
          router.push(listRouter);
        }
      });
    };

    let CurTem; // 接收模板
    watchEffect(() => {
      if (configData.value.template) {
        // 设置当前应当渲染的模板
        CurTem = myComponents[configData.value.template];
      }
    });

    onMounted(() => {
      setConfigData();
    });
    return () => (
      <div class="list-config">
        <header>
          <MPageHeader title={route.params.title} router="list" type="link" />
        </header>
        <main style={{ padding: '20px' }}>
          <MFormSkeleton loading={loading.value}>
            {configData.value.template ? (
              <CurTem ref={curComponent} data={configData.value} onDataChange={changeSaveData} />
            ) : (
              ''
            )}
          </MFormSkeleton>
        </main>
        <footer>
          <MFormFooter onSave={save} loading={saveLoading.value} />
        </footer>
      </div>
    );
  },
});
</script>
