import request from '@/tools/request';

export const getConfig = (params: any, loading: any) => {
  return request({
    url: '/detailmarkettool-config-edit.html',
    loading,
    params,
  });
};

export const saveConfig = (id: string, data: any, loading: any) => {
  return request({
    url: '/detailmarkettool-config-edit.html?id=' + id,
    method: 'post',
    loading,
    data,
  });
};
